<!-- 
@Author ——ZGM
@Date ——2022年
-->
<template>
  <div class="gas">
    <!-- banner -->
    <div class="banner"></div>

    <div class="LinkSulution">
      <div class="LinkContariner">
        <div class="left" :class="{ moveSuLeft: isMove }">
          <p>领氪智慧燃气解决方案</p>
          <p>Smart Gas</p>
          <p>
            <span></span>
          </p>
          <p class="commonP_my">
            领氪智慧燃气旨在通过数字化、信息化、智能化的技术手段，实现城市燃气生产、输
          </p>
          <p class="commonP_my">
            配、运营等环节的安全、高效运行，以帮助政府和公用事业单位提升对城市燃气的安
          </p>
          <p class="commonP_my">全防控能力和智能化管理水平。</p>
        </div>
        <div class="right" :class="{ moveSuRight: isMove }">
          <img src="../../assets/images/gas/gas_1.png" alt="" />
        </div>
      </div>

      <!-- <div class="colover_1"></div>
      <div class="colover_2"></div> -->
    </div>

    <!-- 安全 -->
    <div class="security">
      <ul>
        <li v-for="(item, index) in threeOptions" :key="index" :class="{ liSca: isLI }">
          <div class="icon">
            <img :src="item.icon" alt="#" />
          </div>
          <p class="title">{{ item.title }}</p>
          <p class="commonP">{{ item.p1 }}</p>
          <p class="commonP">{{ item.p2 }}</p>
          <p class="commonP">{{ item.p3 }}</p>
          <p class="commonP">{{ item.p4 }}</p>
        </li>
      </ul>
    </div>

    <!-- plat -->
    <div class="plat">
      <div class="title">
        <div class="move_header_title" :class="{ moveTitle: isBottom }">
          <p>智慧燃气管控一体化平台</p>
          <p>Smart Gas management and control integrated platform</p>
        </div>
      </div>
      <div class="fnContainer">
        <div class="left" :class="{ moveLeft: isBottom }">
          <img src="../../assets/images/gas/gas_2.png" alt="" />
        </div>
        <div class="right" :class="{ moveRight: isBottom }">
          <ul>
            <li>
              <div class="liLeft_1">
                <span>01</span>
              </div>
              <div class="liRight">
                <div class="rightTitle">
                  <p><b>管控一体</b></p>
                  <p class="my_common_p">通过信息化与自动化的高度融合，实现从管理</p>
                  <p class="my_common_p">到控制的无缝衔接，从而做到快速响应和高效</p>
                  <p class="my_common_p">运转</p>
                </div>
              </div>
            </li>

            <li>
              <div class="liLeft_1">
                <span>02</span>
              </div>
              <div class="liRight">
                <div class="rightTitle">
                  <p><b>全面感知</b></p>
                  <p class="my_common_p">应用最先进的物联网和传感技术，实现燃气生</p>
                  <p class="my_common_p">产、输配、运营全过程的实时感知，全面掌握</p>
                  <p class="my_common_p">生产运行态势</p>
                </div>
              </div>
            </li>

            <li>
              <div class="liLeft_1">
                <span>03</span>
              </div>
              <div class="liRight">
                <div class="rightTitle">
                  <p><b>弹性伸缩</b></p>
                  <p class="my_common_p">基于微服务架构搭建，让系统的实现和部署更</p>
                  <p class="my_common_p">加标准化、简单化，并具有更强的可扩展性和</p>
                  <p class="my_common_p">灵活性</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- 覆盖 -->
      <!-- <div class="coloverLeft"></div>
      <div class="coloverRight"></div> -->
    </div>

    <!-- 3D -->
    <div class="my_3D">
      <div class="title">
        <div class="move_header_title" :class="{ moveTitle_bottom: isBottom_1 }">
          <p>基于物联网的数字三维场站系统</p>
          <p>Digital 3D station system based on Internet of things</p>
        </div>
      </div>
      <div class="fnContainer">
        <div class="right_bottom" :class="{ moveRight_bottom: isBottom_1 }">
          <ul>
            <li>
              数字三维场站系统借助于iLink工业物联平台的设备对接和三维可视化能力，利用建筑信息模型(BIM)、数字工艺模型，实现真实场站到数字场站的1:1投影和一体化监控和展示。
            </li>
            <li>
              通过场站全要素的三维可视化展示，实现场站环境、工艺设备、仪器仪表等全视角、无死角的实时监控和预警。
            </li>
            <li>
              通过数字化的工艺体系和大数据AI算法，实现场站运行全过程的虚拟仿真验证，保障场站的运行安全和平稳供气。
            </li>
          </ul>
        </div>
        <div class="left_bottom" :class="{ moveLeft_bottom: isBottom_1 }">
          <img src="../../assets/images/gas/gas_3.jpg" alt="" />
        </div>
      </div>

      <!-- 覆盖 -->
      <!-- <div class="coloverLeft"></div>
      <div class="coloverRight"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      threeOptions: [
        {
          icon: require("../../assets/images/gas/icon_1.png"),
          title: "保障供气安全",
          p1: "基于物联网、大数据、机器学习等技术，结合现代高",
          p2: "技术实时监测手段，建立“智慧燃气安全大脑”，采",
          p3: "取主动的安全分析和实时态势感知，实现燃气安全由",
          p4: "“被动防御”转向“主动智能”",
        },
        {
          icon: require("../../assets/images/gas/icon_2.png"),
          title: "提升运行效率",
          p1: "建立 “信息化+自运化”高度融合的管控一体化平",
          p2: "台，实现生产运行与经营管理的无缝集成与管控一",
          p3: "体，提升燃气企业的运行效率，从而推动企业的数",
          p4: "字化转型和组织流程再造",
        },
        {
          icon: require("../../assets/images/gas/icon_3.png"),
          title: "提高智能化水平",
          p1: "建立不断演进、深化的大数据分析平台，提供强大",
          p2: "的数据管理能力和丰富的数据挖掘、分析手段，并",
          p3: "充分吸纳和利用内外数据，提高燃气企业经营决策",
          p4: "的智能化水平",
        },
      ],
      isMove: false,
      isLI: false,
      isBottom: false,
      isBottom_1: false,
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    // 元素滚动监听
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量

      if (scrollTop < 1055 && !this.isMove) {
        this.isMove = true;
      }
      if (
        (scrollTop > 220 && scrollTop < 230 && !this.isLI) ||
        (scrollTop < 1440 && scrollTop > 230 && !this.isLI)
      ) {
        this.isLI = true;
      }

      if (
        (scrollTop > 666 && scrollTop < 700 && !this.isBottom) ||
        (scrollTop < 2133 && scrollTop > 700 && !this.isBottom)
      ) {
        this.isBottom = true;
      }

 
      if (
        (scrollTop >= 1500 && scrollTop <= 2240 && !this.isBottom_1)
       
      ) {
        this.isBottom_1 = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.gas {
  .banner {
    height: 500px;
    background: url("../../assets/images/gas/banner.jpg") no-repeat center;
    background-size: cover;
  }

  .LinkSulution {
    height: 438px;
    padding-top: 106px;
    position: relative;
    overflow: hidden;
    .LinkContariner {
      width: 1200px;
      height: 538px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      position: relative;
      overflow: hidden;
      .left {
        margin-top: 70px;
        width: 600px;
        position: absolute;
        left: -470px;
        p:nth-child(1) {
          color: #1f87e8;
          font-family: "Microsoft YaHei";
          font-size: 32px;
          font-weight: 400;
          height: 42px;
          line-height: 42px;
          letter-spacing: 1px;
        }

        p:nth-child(2) {
          color: #1f87e8;
          font-family: "Microsoft YaHei";
          font-size: 46px;
          font-weight: 600;
          padding-left: 5px;
          letter-spacing: 1px;
          height: 60px;
          line-height: 60px;
        }
        p:nth-child(3) {
          width: 90px;
          height: 6px;
          background: #4b4b4b;
          margin: 36px 0 30px 0;

          span {
            display: block;
            width: 3px;
            height: 6px;
            background: #fff;
            margin: 0 auto;
          }
        }
        .commonP_my {
          color: #898989;
          font-family: "Microsoft YaHei";
          font-size: 14px;
          height: 26px;
          line-height: 26px;
          font-weight: 400;
          letter-spacing: 1px;
        }
      }

      .right {
        width: 474px;
        height: 326px;
        position: absolute;
        right: -600px;
        img {
          width: 474px;
          height: 326px;
        }
      }
    }
    .colover_1 {
      position: absolute;
      left: 0;
      top: 0;
      background: #fff;
      height: 644px;
      width: 350px;
    }

    .colover_2 {
      position: absolute;
      right: 0;
      top: 0;
      background: #fff;
      height: 644px;
      width: 350px;
    }
  }

  .security {
    height: 454px;
    box-sizing: border-box;
    padding-top: 62px;
    ul {
      height: 454px;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      list-style: none;
      li {
        width: 372px;
        height: 302px;
        transform: scale(0.99, 0.99);
        opacity: 0;
        .icon {
          width: 82px;
          height: 82px;
          border-radius: 50%;
          background: #1773cd;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;

          img {
            width: 45px;
            height: 46px;
          }
        }

        .title {
          font-family: "Microsoft YaHei";
          font-size: 20px;
          color: #333;
          text-align: center;
          margin-top: 16px;
          margin-bottom: 50px;
          position: relative;
        }

        .title::before {
          content: "";
          border-bottom: 1px solid #ccc;
          position: absolute;
          bottom: -14px;
          width: 34px;
          left: 50%;
          transform: translate(-50%);
        }

        .commonP {
          color: #666;
          font-size: 14px;
          font-family: "Microsoft YaHei";
          text-align: center;
          line-height: 26px;
        }
      }
    }
  }

  .plat {
    height: 688px;
    box-sizing: border-box;
    padding-top: 80px;
    position: relative;
    overflow: hidden;
    background: #f6f6f6;
    .title {
      font-size: 30px;
      color: #1f87e8;
      font-family: "Microsoft YaHei";
      text-align: center;
      position: relative;
      height: 50px;
      .move_header_title {
        position: absolute;
        width: 100%;
        position: absolute;
        right: -900px;
      }
      p:last-child {
        color: #a9a9a9;
        font-size: 22px;
        font-family: "Microsoft YaHei";
      }
    }
    .fnContainer {
      height: 490px;
      width: 1200px;
      margin: 0 auto;
      margin: 0 auto;
      margin-top: 58px;
      display: flex;
      justify-content: space-around;
      padding-left: 70px;
      position: relative;
      .left {
        width: 600px;
        height: 422px;
        position: absolute;
        left: -600px;

        img {
          width: 600px;
          height: 422px;
        }
      }

      .right {
        width: 510px;
        height: 326px;
        position: absolute;
        right: -520px;
        ul {
          list-style: none;
          li {
            display: flex;
            margin-bottom: 30px;
            display: flex;

            .liLeft_1 {
              background: #1569bc;
              height: 60px;
              width: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              transform: rotate(-45deg);

              margin-top: 10px;
              margin-right: 50px;

              span {
                color: #fff;
                font-size: 28px;
                font-family: "Microsoft YaHei";
                font-weight: bold;
                transform: rotate(45deg);
              }
            }

            .liRight {
              min-width: 280px;
              .rightTitle {
                padding-top: 5px;
                p:first-child {
                  padding: 0;
                  font-size: 20px;
                  margin-bottom: 5px;
                  color: #000;
                  font-family: "Microsoft YaHei";
                }
                .my_common_p {
                  color: #666;
                  font-size: 13px;
                  font-family: "Microsoft YaHei";
                  line-height: 28px;
                }
              }
            }
          }
        }
      }
    }

    .coloverLeft {
      background: #f6f6f6;
      height: 688px;
      width: 400px;
      position: absolute;
      left: 0;
      top: 0;
    }

    .coloverRight {
      background: #f6f6f6;
      height: 688px;
      width: 400px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .my_3D {
    height: 616px;
    box-sizing: border-box;
    padding-top: 80px;
    position: relative;
    overflow: hidden;
    .title {
      font-size: 30px;
      color: #1f87e8;
      font-family: "Microsoft YaHei";
      text-align: center;
      position: relative;
      height: 50px;
      .move_header_title {
        position: absolute;
        width: 100%;
        position: absolute;
        right: -900px;
      }
      p:last-child {
        color: #a9a9a9;
        font-size: 22px;
        font-family: "Microsoft YaHei";
      }
    }
    .fnContainer {
      height: 616px;
      width: 1200px;
      margin: 0 auto;
      margin-top: 100px;
      padding-left: 110px;
      display: flex;
      position: relative;
      .left_bottom {
        width: 633px;
        height: 300px;
        position: absolute;
        right: -574px;

        img {
          width: 633px;
          height: 300px;
        }
      }

      .right_bottom {
        width: 350px;
        height: 300px;
        margin-right: 80px;
        position: absolute;
        left: -300px;
        ul {
          list-style: none;
          padding-top: 10px;
          li {
            line-height: 28px;
            color: #666;
            font-family: "Microsoft YaHei";
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 25px;
          }
        }
      }
    }

    .coloverLeft {
      background: #fff;
      height: 688px;
      width: 400px;
      position: absolute;
      left: 0;
      top: 0;
    }

    .coloverRight {
      background: #fff;
      height: 688px;
      width: 400px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
</style>

<style lang="less" scoped>
.moveSuLeft {
  animation: suLeft_1 1s ease forwards;
}

.moveSuRight {
  animation: suRight_1 1s ease forwards;
}

.liSca {
  animation: liSca_1 1s ease forwards;
}

.moveTitle {
  animation: moveT 1s ease forwards;
}

.moveLeft {
  animation: moveT_1 1s ease forwards;
}
.moveRight {
  animation: moveT_2 1s ease forwards;
}

.moveTitle_bottom {
  animation: moveT_bottom 1s ease forwards;
}

.moveLeft_bottom {
  animation: moveT_1_bottom 1s ease forwards;
}
.moveRight_bottom {
  animation: moveT_2_bottom 1s ease forwards;
}

@keyframes suLeft_1 {
  from {
    left: -470px;
  }
  to {
    left: 0;
  }
}

@keyframes suRight_1 {
  from {
    right: -600px;
  }
  to {
    right: 0px;
  }
}
@keyframes liSca_1 {
  from {
    opacity: 0;
    transform: scale(0.99, 0.99);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@keyframes moveT {
  from {
    right: -900px;
  }
  to {
    right: 0px;
  }
}

@keyframes moveT_1 {
  from {
    left: -600px;
  }
  to {
    left: 100px;
  }
}

@keyframes moveT_2 {
  from {
    right: -510px;
  }
  to {
    right: 0;
  }
}

@keyframes moveT_bottom {
  from {
    right: -900px;
  }
  to {
    right: 0px;
  }
}

@keyframes moveT_1_bottom {
  from {
    right: -574px;
  }
  to {
    right: 100px;
  }
}

@keyframes moveT_2_bottom {
  from {
    left: -300px;
  }
  to {
    left: 130px;
  }
}
</style>
